define("ember-accordion/components/accordion-panel", ["exports", "ember-accordion/templates/components/accordion-panel"], function (_exports, _accordionPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _accordionPanel.default,
    classNames: ["AccordionPanel"],
    classNameBindings: ["isActive"],
    // Inputs
    panelName: "panel-one",
    isActive: Ember.computed("activePanelName", "panelName", function () {
      return Ember.isEqual(this.get("activePanelName"), this.get("panelName"));
    }),
    openOnInit: false,

    _activateDefaultPanel() {
      if (this.isDestroying) {
        return;
      }

      if (this.get("openOnInit")) {
        this.get("toggle")(this.get("panelName"));
      }
    },

    init() {
      this._super(...arguments);

      Ember.run.next(() => {
        this.get("register")(this.get("panelName"));

        this._activateDefaultPanel();
      });
    },

    willDestroyElement() {
      this.get("unregister")(this.get("panelName"));
    }

  });

  _exports.default = _default;
});