define("ember-accordion/components/accordion-toggle", ["exports", "ember-accordion/templates/components/accordion-toggle"], function (_exports, _accordionToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _accordionToggle.default,
    classNames: ["AccordionToggle"],
    classNameBindings: ["isActive", "disabled"],
    // Input params
    disabled: null,
    panelName: "panel-one",
    isActive: Ember.computed("activePanelName", "panelName", function () {
      return Ember.isEqual(this.get("activePanelName"), this.get("panelName"));
    }),
    click: function () {
      if (!this.get("disabled")) {
        this.get("toggle")(this.get("panelName"));
      }

      return false;
    },
    actions: {
      openItem() {
        if (!this.isActive) {
          this.get("toggle")(this.get("panelName"));
        }
      },

      closeItem() {
        if (this.isActive) {
          this.get("toggle")(this.get("panelName"));
        }
      },

      toggleItem() {
        this.get("toggle")(this.get("panelName"));
      }

    }
  });

  _exports.default = _default;
});