define("ember-accordion/templates/components/accordion-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IgNF4/PA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,[\"isActive\"]],[27,\"hash\",null,[[\"open\",\"close\",\"toggle\"],[[27,\"action\",[[22,0,[]],\"openItem\"],null],[27,\"action\",[[22,0,[]],\"closeItem\"],null],[27,\"action\",[[22,0,[]],\"toggleItem\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-accordion/templates/components/accordion-toggle.hbs"
    }
  });

  _exports.default = _default;
});