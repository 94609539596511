define("ember-accordion/templates/components/accordion-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gZd7dOff",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"toggle\",\"panel\",\"close\"],[[27,\"component\",[\"accordion-toggle\"],[[\"activePanelName\",\"toggle\"],[[22,0,[\"activePanel\"]],[27,\"action\",[[22,0,[]],\"togglePanel\"],null]]]],[27,\"component\",[\"accordion-panel\"],[[\"activePanelName\",\"toggle\",\"register\",\"unregister\"],[[22,0,[\"activePanel\"]],[27,\"action\",[[22,0,[]],\"togglePanel\"],null],[27,\"action\",[[22,0,[]],[23,[\"register\"]],[23,[\"itemId\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"unregister\"]],[23,[\"itemId\"]]],null]]]],[27,\"action\",[[22,0,[]],[23,[\"close\"]],[23,[\"itemId\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-accordion/templates/components/accordion-item.hbs"
    }
  });

  _exports.default = _default;
});