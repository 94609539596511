define("ember-accordion/components/accordion-list", ["exports", "ember-accordion/templates/components/accordion-list", "ember-accordion/utils/item"], function (_exports, _accordionList, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _accordionList.default,
    classNames: ["AccordionList"],
    // Input params
    allowManyActiveItems: false,
    // Internal state
    _activeItems: null,
    _registeredItems: null,

    init() {
      this._super(...arguments);

      this.set("_activeItems", Ember.A([]));
      this.set("_registeredItems", Ember.A([]));
    },

    // If each registered item has at least on panel open, then return true
    allExpanded: Ember.computed("_activeItems.[]", "_registeredItems.[]", function () {
      let {
        _activeItems,
        _registeredItems
      } = this.getProperties("_activeItems", "_registeredItems");

      let anyMissing = _registeredItems.reduce((anyMissing, item) => anyMissing || Ember.isNone(_activeItems.findBy("id", item.itemId)), false);

      return !anyMissing;
    }),
    actions: {
      toggleItem(itemId, panelName) {
        const activeItems = this.get("_activeItems");
        const targetItem = activeItems.findBy("id", itemId); // if target item is already active

        if (targetItem) {
          // and the panel is already active
          if (Ember.isEqual(targetItem.get("panel"), panelName)) {
            activeItems.removeObject(targetItem);
          } else {
            targetItem.set("panel", panelName);
          }
        } else {
          // if simultanious active items are not allow, clear array
          if (!this.get("allowManyActiveItems")) {
            activeItems.clear();
          }

          let newItem = _item.default.create({
            id: itemId,
            panel: panelName
          });

          activeItems.addObject(newItem);
        }
      },

      closeItem(itemId) {
        let activeItems = this.get("_activeItems");
        const activeItem = activeItems.findBy("id", itemId);
        activeItems.removeObject(activeItem);
      },

      expandAll(panelName) {
        panelName = panelName || "panel-one";
        let {
          _activeItems,
          _registeredItems
        } = this.getProperties("_activeItems", "_registeredItems");

        _registeredItems.forEach(registeredItem => {
          if (registeredItem.panelName === panelName) {
            _activeItems.addObject(_item.default.create({
              id: registeredItem.itemId,
              panel: panelName
            }));
          }
        });
      },

      collapseAll() {
        this.get("_activeItems").clear();
      },

      // private action, allow panel's to register themselves so they can participate in expand/collapse all
      register(itemId, panelName) {
        this.get("_registeredItems").addObject({
          itemId,
          panelName
        });
      },

      // private action
      unregister(itemId, panelName) {
        this.get("_registeredItems").removeObject({
          itemId,
          panelName
        });
      }

    }
  });

  _exports.default = _default;
});