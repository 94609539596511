define("ember-accordion/components/accordion-item", ["exports", "ember-accordion/templates/components/accordion-item"], function (_exports, _accordionItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _accordionItem.default,
    classNames: ["AccordionItem"],
    itemId: Ember.computed(function () {
      return this.elementId;
    }),
    // item is active if there is an item in activeItems matching 'itemId'
    isActive: Ember.computed.notEmpty("ownActiveItem"),
    ownActiveItem: Ember.computed("activeItems.@each.id", "itemId", function () {
      return this.get("activeItems").findBy("id", this.get("itemId"));
    }),
    activePanel: Ember.computed("isActive", "ownActiveItem.panel", function () {
      if (!this.get("isActive")) {
        return null;
      }

      return this.get("ownActiveItem.panel");
    }),
    actions: {
      togglePanel(panelName) {
        this.get("toggle")(this.get("itemId"), panelName);
      }

    }
  });

  _exports.default = _default;
});